<template>
  <div class="chat_div">
    <div class="chat_ln">
      <div class="left" v-if="false">
        <div class="title">切换写作风格</div>
        <div
          class="list"
          :class="{ active: active == index }"
          @click="active = index"
          v-if="index < isGs"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item }}
        </div>
        <div class="gdsl" @click="isGs == 5 ? (isGs = 100) : (isGs = 5)">
         
          <i
            :class="isGs == 5 ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
          ></i>
        </div>
      </div>
      <div class="right">
        <!-- <div class="chat_ln_1" v-if="isMobile">
            <div class="box">
                <el-dropdown trigger="click" @command="handleCommandA">
                    <div class="button">
                        {{param.select_style}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="(item, index) in list" :key="index">{{item}}</el-dropdown-item>
                    
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div> -->
        <div class="chat_ln_2" ref="mianscroll">
          <div class="list" v-for="(item, index) in listQuery" :key="index" >
            <template v-if="item.divider">
                <div class="divider"><div class="text">对话已过期，开始新对话</div></div>
            </template>
            <template v-else>
                <div class="list_top">
                 
                  <div class="fz_button" @click="onCopyWotile(index)" v-if="!isMobile">一键复制</div>
                  <div class="name" v-if="!isMobile">{{account.username['0']}}</div>
                  <div class="text" v-if="!isMobile" v-html="item.woTitle.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"> </div>
                   <div class="text" v-if="isMobile" v-html="item.woTitle.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"> </div>
                </div>

                <div class="list_bottom_b"> 
                   <div class="list_bottom">
                      <div class="fz_button" @click="onCopy(index)" v-if="!isMobile">一键复制</div>
                      <div class="name" v-if="!isMobile">AI</div>
                      <!-- <div
                          class="text"
                          v-html="item.taTitle ? item.taTitle : 'AI输入中...'"
                      ></div> -->
                      <mavon-editor
                      v-if="item.taTitle"
                      class="md_box"
                          :value="item.taTitle"
                          :subfield="prop.subfield" 
                          :defaultOpen="prop.defaultOpen"
                          :toolbarsFlag="prop.toolbarsFlag"
                          :editable="prop.editable"
                          :scrollStyle="prop.scrollStyle" />
                      <div class="text" v-else>
                    
                        <div class="loadingThree">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                      
                      </div>

                      
                      

                    </div>

                    <!-- 复制 -->

                    <!-- <div class="copy" v-if="isMobile&&!isLoading"  @click="onCopy(index)" >
                      <img src="@/assets/images/copy.svg" class="img" />
                    </div> -->
                    
                    <div class="oncemore" v-if="isMobile&&!isLoading"  @click="oncemore()" >
                      <img src="@/assets/images/onceagain.svg" class="img" />
                    </div>

                    

                    


                </div>

                <div class="stop" v-if="isMobile&&isLoading"  @click="stop()" >
                   <!-- <div class="generate_text">
                      正在生成中
                    </div> -->
                    <!-- <div class="loadingThree">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                     -->

                     <!-- <span class="typing-cursor" v-if="isLoading&&!isListQueryEmpty">生成中|</span> -->
                   
                  <div class="squ"></div>
                  <div class="text_stop">停止回答</div>
                      
                      
                </div>


                <div class="appraise_box" v-if="!item.isEvaluate" v-show="isAsk">
                    <div class="title">以上结果是否满意？ </div>
                    <div class="boxs">
                        <div class="box" @click="getEngineFeedback(2,'',item.woTitle,item.taTitle,index)">
                            <img src="@/assets/images/appraise1.svg" class="img" />
                            <div v-if="!isMobile">好评</div> 
                        </div>
                        <div class="box" @click="getEngineFeedback(1,'',item.woTitle,item.taTitle,index)">
                            <img src="@/assets/images/appraise2.svg" class="img" />
                            <div v-if="!isMobile">中评</div> 
                        </div>
                        <el-popover
                            placement="bottom"
                            trigger="click">
                            <div class="box" slot="reference">
                                <img src="@/assets/images/appraise3.svg" class="img" />
                               <div v-if="!isMobile">差评</div> 
                            </div>
                            <div class="common_button">
                                <div class="button_div" @click="getEngineFeedback(0,'事实错误',item.woTitle,item.taTitle,index)">事实错误</div>
                                <div class="button_div" @click="getEngineFeedback(0,'信息有害',item.woTitle,item.taTitle,index)">信息有害</div>
                                <div class="button_div" @click="getEngineFeedback(0,'无意义灌水',item.woTitle,item.taTitle,index)">无意义灌水</div>
                                <div class="button_div" @click="getEngineFeedback(0,'其他',item.woTitle,item.taTitle,index)">其他</div>
                            </div>
                        </el-popover>
                        
                    </div>
                </div>
              
            </template>
            
          </div>
          <!-- <div class="list">
                <div class="list_top">
                    <div class="name">H</div>
                    <div class="text">写一篇300字文章，关于如何保护地球</div>
                </div>
                <div class="list_bottom">
                    <div class="name">AI</div>
                    <div class="text">保护地球是xxxxxxxxxxxxxxxxXxxxxxxxxxxxxxXxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxXxxxxxxxxxxxxxXxxxxxxxxxxxxxxxx xxxxxxxxxxxXxxxxxxxxxxxxxXxxxxxxxxxxxxxxxx</div>
                </div>
                <div class="appraise_box">
                    <div class="title">以上结果是否满意？ </div>
                    <div class="boxs">
                        <div class="box">
                            <img src="@/assets/images/appraise1.png" class="img" />
                            惊喜
                        </div>
                        <div class="box">
                            <img src="@/assets/images/appraise2.png" class="img" />
                            还行
                        </div>
                        <div class="box">
                            <img src="@/assets/images/appraise3.png" class="img" />
                            糟糕
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
      </div>
      <div class="chat_ln_3_fixed">
        <div class="buts" >
           
            <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-delete" @click="emptyFun(true)">清除对话</el-button>
            <!-- <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-refresh-right" @click="oncemore()">换个回答</el-button> -->
            <el-button :disabled="isListQueryEmpty||isLoading" icon="el-icon-document-checked" @click="getEngineSavedialog()">保存对话</el-button>
          
        </div>
       
        <div class="chat_ln_3">
          <div class="chat-input-small">
            <div class="chat-input-textarea">
              <textarea
                ref="test"
                class="textarea_input"
                :value="param.query"
                @input="handleQueryInput"
                @keydown.enter="exactA"
                placeholder="告诉AI你想要它生成的内容"
              ></textarea>
              <div
                class="textarea_ghost"
                v-html="formatTextToHtml(param.query)"
              ></div>
            </div>
            <div class="chat-input-button">
              <div class="chat-input-button-inner">
                <div class="button_box">
                  <img
                    class="fs_button"
                    @click="getEngineQuery()"
                    src="@/assets/images/new/enter.svg"
                  />
                </div>
              </div>
            </div>
            <div class="ms">Shift+Enter输入框内换行</div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { engineQuery,payAccountcheck,logout,engineFeedback,engineSavedialog} from "@/api/user";
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import axios from "axios";

// var t = document.getElementById("left");
// t.style.visibility = 'hidden'
// document.getElementById("left").style.visibility="hidden"
export default {
    components:{// 在页面中注册组件
        mavonEditor 
    },
  data() {
    return {
      active: 0,
      list: [
        "无风格(默认)",
        "小学生",
        "科技文",
        "猫猫语",
        "跨语言",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
        "其他自己放",
      ],
      isGs: 5,
      value: "",

      engineQuery: [],
      param: {
        select_style: "无风格（默认）",
        select_lang: "简体中文（默认）",
        query: "",
        ask_again:0,
        new_session: 1,
      },
      historychat:{
        woTitle:[],
        taTitle:[]

      },
      listQuery: [],
      isAsk: true,
      isLoading:false,//是否正在生成



      
      aiAnswer:'',
        account:{},
        isMobile:"",
        timer:null,
        thisData:0,
        lastquery:"",
        //会话过期
        timerSession:null,
        thisDataSession:0,
    };
  },
  computed: {
    isListQueryEmpty(){
      var that = this;
      return that.listQuery.length ===0;

    },
    prop () {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域 
        editable: false,    // 是否允许编辑
        toolbarsFlag: false,
        scrollStyle: true
      }
      return data
    }
  },
  watch: {
  },
  mounted() {
    var that = this;
    that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    that.param.query = localStorage.getItem("chatValue") || '';
    that.getPayAccountcheck();

  },
  methods: {
    // 保存对话
    getEngineSavedialog(){
       var that = this;
      // 如果没有提示过，则弹出提示弹窗
      if (!localStorage.getItem("prompted")) {
          that.$confirm(`请在右上角查看历史对话。`, "提示", {
            confirmButtonText: "知道了",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              localStorage.setItem("prompted", true); // 设置为已提示
              engineSavedialog().then((res) => {
                if(res.code==0){
                    that.$message.success("保存成功");
                }
            });
            })
            .catch(() => {});
      } else {  // 否则在保存后直接存储状态，以便下次使用
        localStorage.setItem("prompted", true);
         engineSavedialog().then((res) => {
                if(res.code==0){
                    that.$message.success("保存成功");
                }
            });
      }
    },
    getEngineFeedback(rate,reason,query,answer,index){
        var that = this;
        var param={
            "rate":rate,
            "query":query,
            "answer":answer,
            "session_id":that.param.session_id,
        }
        if(reason){
            param.reason=reason
        }
        engineFeedback(param).then((res) => {
            if(res.code==0){
                that.$message.success("收到！积分+1");
                that.$set(that.listQuery[index],"isEvaluate",true)
            }
        });
    },
    guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },



     // 再问一遍
    oncemore(){
      var that = this;
      that.isLoading = true;
    //   that.listQuery[that.listQuery.length - 1].isEvaluate =false
      //判断是否过期
      if(that.account.multi_turn==1&&!that.timerSession){
        return false;
      }
      that.getmoreQuery( that.lastquery)
    },
    
    
    //清空会话
    emptyFun(is){
        var that = this;
        let scrollEl = this.$refs.mianscroll;
        if(is){
            that.$confirm('是否清除对话', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    that.listQuery=[];
                    that.param= {
                        select_style: "无风格（默认）",
                        select_lang: "简体中文（默认）",
                        query: "",
                        new_session: 1,
                    }
                    //是否多轮
                    if(that.account.multi_turn==1){
                        that.param.session_id=that.guid();
                    }
                    clearInterval(that.timerSession);
                    that.timerSession=null;
                    scrollEl.scrollTo({ top: 0, behavior: "instant" });
                }).catch(() => {
            });
        }else{
            that.listQuery=[];
            that.param= {
                select_style: "无风格（默认）",
                select_lang: "简体中文（默认）",
                query: "",
                new_session: 1,
            }
            //是否多轮
            if(that.account.multi_turn==1){
                that.param.session_id=that.guid();
            }
            clearInterval(that.timerSession);
            that.timerSession=null;
            scrollEl.scrollTo({ top: 0, behavior: "instant" });
        }
    },
    //复制用户query

    onCopyWotile(index){
      var that = this;
      this.$copyText(that.listQuery[index].woTitle).then(
	      e=>{
	        that.$message.success("复制成功");
	      },
	      e=>{
            that.$message.error("复制失败");
	      }
      )

    },
    //复制
    onCopy(index) {
        var that = this;
      this.$copyText(that.listQuery[index].taTitle).then(
	      e=>{
	        that.$message.success("复制成功");
	      },
	      e=>{
            that.$message.error("复制失败");
	      }
      )
    },
    exactA(e) {
      var that = this;
      if(e.keyCode == 13 && e.ctrlKey){
        that.param.query += "\n"; //换行
      }else if(e.keyCode == 13 && e.shiftKey){
        that.value += "\n"; //换行
      }else if (e.keyCode == 13) {
        that.getEngineQuery();
        e.preventDefault();//禁止回车的默认换行
      }
    },

    

    getEngineQuery() {
      var that = this;
      that.isLoading = true;
      that.cancelTokenSource = axios.CancelToken.source()
      if (that.param.query) {
        that.lastquery = that.param.query
        if (!that.isAsk) {
          return false;
        }
        let scrollEl = this.$refs.mianscroll;
        that.isAsk = false;
        that.listQuery.push({
          woTitle: that.param.query,
          taTitle: "",
          isEvaluate:true,
        });
        var param=JSON.parse(JSON.stringify(that.param))
        that.param.query="";

        that.$nextTick(function(){
            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
        })
        that.thisDataSession=0;
        that.thisData=0
        that.timer=window.setInterval(function(){
            if(that.thisData>=15){
                that.isAsk = true
                that.listQuery[that.listQuery.length - 1].taTitle+='\n【系统提示：请求超时，请重试】';
                // that.listQuery[that.listQuery.length - 1].isEvaluate=true;
                // that.$set(that.listQuery[index],"isEvaluate",true)
                clearInterval(that.timer);
            }else{
                that.thisData++;
                console.log(that.thisData)
            }
        },1000);

        axios({
          url: "api/engine/query",
          method: "post",
          responseType:"stream",
          cancelToken:that.cancelTokenSource.token,
          headers: {
            "Content-Type": "application/json;",
          },
          
          data: param,
          onDownloadProgress: res => {
           
           
            that.thisData=1;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })
            // console.log("that.param.session_id:",that.param.session_id)

  
             that.listQuery[that.listQuery.length - 1].taTitle =
            res.currentTarget.response;

          

          },
          
         
        }).then((res) => {
            clearInterval(that.timer);
            //是否多轮
            console.log("计时器的状态",that.timerSession)
            if(that.account.multi_turn==1&&!that.timerSession){
                that.timerSession=window.setInterval(function(){
                    if(that.thisDataSession>=900){
                        that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.$nextTick(function(){
                            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                        })
                        clearInterval(that.timerSession);
                        that.timerSession=null;
                    }else{
                        that.thisDataSession++;
                        console.log(that.thisDataSession)
                    }
                },1000);
            }
            if(typeof(res.data)=="string"){
                that.listQuery[that.listQuery.length - 1].isEvaluate=false;
            }
            if(res.data.code==405||res.data.code==10){
                that.$message.error(res.data.result.message);
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            }
            
            if(res.data.code==30){
             
                that.listQuery[that.listQuery.length - 1].taTitle='对话已过期，开始新对话'
            
                that.$nextTick(function(){
                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                })

                that.param.new_session=1;
                that.param.session_id=that.guid();

                that.param.query = that.listQuery[that.listQuery.length - 1].woTitle

                var param=JSON.parse(JSON.stringify(that.param))
               
                // console.log("param:",param)

                axios({
                  url: "api/engine/query",
                  method: "post",
                  responseType:"stream",
                  headers: {
                    "Content-Type": "application/json;",
                  },
                  data: param,
                  onDownloadProgress: function (res){
                   
                    that.thisData=1;
                    that.$nextTick(function(){
                        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                    })

                    that.listQuery[that.listQuery.length - 1].taTitle =
                    res.currentTarget.response;

                  },
              
                }).then((res) => {
                    that.param.query = '';
                    that.isLoading = false;
                    // that.thisDataSession=0;
                    // that.thisData=0;
                    that.isAsk = true;//显示评价
                
                    })
                .catch((error) => {
                  // 错误处理逻辑
                });

            }
            else if(res.data.code==20){
              console.log("res",res)
              that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            }
            else if(res.data.code==40){
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
                that.$confirm(res.data.result.message, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //去登录
                       that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.getEngineQuery()
                        // logout().then((res) => {
                        //     if(res.code==0){
                        //         that.$router.push({ path: "/login" });
                        //     }
                        // });
                    }
                });
                that.isAsk = true;
                
                return false;
            }
            that.isAsk = true;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })
            that.param.new_session=0;
            that.isLoading = false;
            // that.lastquery = that.listQuery[that.listQuery.length - 1].woTitle
        }).catch((error) => {
            clearInterval(that.timer);
            that.isAsk = true;
            if(error.response.status==401){
                that.$router.push({
                    path: "/login",
                });
            }
        }
       
        );
      }
    },
    //换个回答
     getmoreQuery(query) {
      var that = this;
      that.isLoading = true;
       that.cancelTokenSource = axios.CancelToken.source()
     
      if (!that.isAsk) {
        return false;
      }
      that.param.query = query
      that.param.ask_again = 1
      let scrollEl = this.$refs.mianscroll;
      that.isAsk = false;
       
      var param=JSON.parse(JSON.stringify(that.param))
      that.param.query="";

      that.$nextTick(function(){
          scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
      })
      that.thisDataSession=0;
      that.thisData=0
      that.timer=window.setInterval(function(){
            if(that.thisData>=15){
                that.isAsk = true
                that.listQuery[that.listQuery.length - 1].taTitle+='\n【系统提示：请求超时，请重试】';
                // that.listQuery[that.listQuery.length - 1].isEvaluate=true;
                clearInterval(that.timer);
            }else{
                that.thisData++;
            }
        },1000);

      axios({
        url: "api/engine/query",
        method: "post",
        cancelToken:that.cancelTokenSource.token,
        responseType:"stream",
        headers: {
          "Content-Type": "application/json;",
        },
        data: param,
        onDownloadProgress: function (res) {
         
          that.$nextTick(function(){
              scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
          })
          // console.log("is_askagain:",that.isask_again)


          that.listQuery[that.listQuery.length - 1].taTitle =
          res.currentTarget.response;

        },
          
         
        }).then((res) => {
            clearInterval(that.timer);
            //是否多轮
            console.log("计时器的状态",that.timerSession)
            if(that.account.multi_turn==1&&!that.timerSession){
                that.timerSession=window.setInterval(function(){
                    if(that.thisDataSession>=900){
                        that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.$nextTick(function(){
                            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                        })
                        clearInterval(that.timerSession);
                        that.timerSession=null;
                    }else{
                        that.thisDataSession++;
                        // console.log(that.thisDataSession)
                    }
                },1000);
            }
            console.log(that.listQuery[that.listQuery.length - 1].isEvaluate)
            if(typeof(res.data)=="string"&&that.listQuery[that.listQuery.length - 1].isEvaluate==false){
                that.listQuery[that.listQuery.length - 1].isEvaluate=false;
            }
            if(res.data.code==405||res.data.code==10){
                that.$message.error(res.data.result.message);
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
            }
             if(res.data.code==30){
                
                that.listQuery[that.listQuery.length - 1].taTitle='对话已过期，开始新对话'
              
                that.$nextTick(function(){
                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                })
                that.param.new_session=1;
                that.param.session_id=that.guid();
                that.param.query = that.listQuery[that.listQuery.length - 1].woTitle
                var param=JSON.parse(JSON.stringify(that.param))
            
                // console.log("param:",param)

                axios({
                  url: "api/engine/query",
                  method: "post",
                  responseType:"stream",
                  headers: {
                    "Content-Type": "application/json;",
                  },
                  data: param,
                  onDownloadProgress: function (res) {
                   
                    that.thisData=1;
                    that.$nextTick(function(){
                        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
                    })

                    that.listQuery[that.listQuery.length - 1].taTitle =
                    res.currentTarget.response;

                  },
              
                }).then((res) => {
                    that.param.query = '';
                    that.isLoading = false;
                    // that.thisDataSession=0;
                    // that.thisData=0;
                    that.isAsk = true;//显示评价
                    
                    
                    })
                .catch((error) => {
                  // 错误处理逻辑
                });
                
               
            }
            else if(res.data.code==40){
                that.listQuery[that.listQuery.length - 1].taTitle=res.data.result.message
                that.$confirm(res.data.result.message, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //去登录
                       that.listQuery.push({"divider":true});
                        that.param.new_session=1;
                        that.param.session_id=that.guid();
                        that.getEngineQuery()
                        // logout().then((res) => {
                        //     if(res.code==0){
                        //         that.$router.push({ path: "/login" });
                        //     }
                        // });
                    }
                });
                that.isAsk = true;
                
                return false;
            }
            that.isAsk = true;
            that.$nextTick(function(){
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "instant" });
            })
            // console.log(res)
            
            that.param.new_session=0;
            that.isLoading = false;
            that.param.ask_again = 0;
             
            // that.lastquery = that.listQuery[that.listQuery.length - 1].woTitle
        }).catch((error) => {
            clearInterval(that.timer);
            that.isAsk = true;
            if(error.response.status==401){
                that.$router.push({
                    path: "/login",
                });
            }
        }
       
        );
      
    },
    getPayAccountcheck(){
        var that = this;
        payAccountcheck().then((res) => {
            if(res.code==0){
                that.account=res.result;
                //是否多轮
                if(that.account.multi_turn==1){
                    that.param.session_id=that.guid();
                }
                if (that.param.query) {
                    localStorage.setItem("chatValue","");
                    that.getEngineQuery();
                }
            }
        });
    },
    handleCommandA(command) {
      var that = this;
      that.param.select_style = command;
    },
    handleCommandB(command) {
      var that = this;
      that.param.select_lang = command;
    },

    // 中断对话
    stop(){
      var that = this;
      that.isLoading = false;
      if(that.cancelTokenSource){
        that.cancelTokenSource.cancel("对话已强制取消")
        // console.log("停止对话")
      }


    },
    handleQueryInput(e) {
      this.param.query = e.target.value;
    },
    formatTextToHtml(value) {
      return value
        .replace(/(\n|\r\n)$/, "<br>&nbsp;")
        .replace(/\n|\r\n/g, "<br>")
        .replace(/ /g, "&nbsp;");
    },

    // toSaveChat(){
    //   //   var that = this;
    //   //   list = that.$refs.list.innerHTML
    //   //   console.log(list)
    //   //   that.$copyText(that.listQuery[index].taTitle).then(
	  //   //   e=>{
	  //   //     that.$message.success("保存成功");
	  //   //   },
	  //   //   e=>{
    //   //       that.$message.error("保存失败");
	  //   //   }
    //   // )
    // },
  },
};
</script>
<style lang="less" scoped>

.typing-cursor {
  display: inline-block;
  font-weight: 100;
  font-size: 1em;
  color: black;
  animation: blink-caret 1s step-end infinite;
  margin-top: 5px;
}

@keyframes blink-caret {
  50% {
    opacity: 0;
  }
}

</style>
